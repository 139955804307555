import React, { Component } from 'react';
import PopulationCounter from '../components/PopulationCounter'
import { Link } from 'react-router-dom';
import {scrollToId} from '../functions/scroll'
import { isMobile } from "react-device-detect";

class MainMenu extends Component {

    background = () => {
    
        if (isMobile){
            return (
                <div className="wallpaper-background"></div>
            )
        }
    
        return (
            <video className="video-background" autoPlay muted loop id="myVideo">
                <source src={require("../images/mirador_home.mp4")} type="video/mp4"/>
                Your browser does not support HTML5 video :(
            </video>
        )
    }

    render() {
        return (
            
            <div className="content fill-page fill-width">
                <div className="page-logo">
                    <Link className="logo-link" to=""></Link>
                </div>

                { this.background() }
                
                <div className="home-description">
                    <PopulationCounter></PopulationCounter>
                </div>

                <div className="horizontal-menu">
                    <ul>
                        <li><a onClick={(e) => {
                            e.preventDefault();
                            const target = "projects";
                            scrollToId("#" + target);
                            }}>WORK</a>
                        </li>
                        <li><Link to="/about">ABOUT</Link>
                        </li>
                        <li><Link to="/shop">SHOP</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default MainMenu
