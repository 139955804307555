import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="content">
        <div className="bio">
            <div className="left half float-left">
                <p><i>Mirador [noun]</i></p>
                <br/>
                <p>A medium, a structure, a space where one can observe in an extended view and in new perspectives.</p>
            </div>
        </div>
        <div className="bio">
            <div className="left half float-left">
                <p><b>Mirador Collective</b> is a London based studio that explores a new discipline of Advocatory Architecture as well as the use of new technologies in storytelling. It is co-founded by two non-conforming individuals, a Hong Konger with a degree in Law, and a Spanish with a background in Architecture. Our work has been shown at the Barbican Gallery in London, at Cyberia – New Media Playground Exhibition by TIFA Working Studio in India, as well as at the #VirtualSurgery Exhibition that we organised.</p>   
            </div>

            <div className="left half float-right">
                <h2 className="page-title">Contacts</h2>
                <p>We are born collaborators. Feel free to contact us if you would like to actualise great ideas together. Please feel free to contact us in English, Spanish and Chinese.</p>
                <br/>
                <p>Email: mirador.collective@gmail.com</p>
                <p>Instagram: <span><a href="https://www.instagram.com/mirador_collective" target="_blank" rel="noopener noreferrer">@mirador_collective</a></span></p>
                <br/>
                <h2 className="page-title">Jobs</h2>
                <p>Currently we do not have open any job or intern positions.</p>
            </div>
        </div>

        <div className="divide-title">Talks, awards & exhibitions</div>

        <div className="bio">
            <div className="left half">
                <p><i>Aesthetica Short Film Festival 2020</i></p>
                <p>an international film festival celebrating independent film from around the world</p>
                <br/>
                <p><i>ACIIID</i></p>
                <p>feature on the virtual design gallery</p>
                <br/>
                <p><i>Cyberia – New Media Playground Exhibition</i></p>
                <p>an immersive exhibition organised by TIFA Working Studio</p>
                <br/>
                <p><i>Capital</i></p>
                <p>a performance & experience on Gentrification at the Barbican</p>
                <br/>
                <p><i>Flux Social</i></p> 
                <p>a talk on Mirador Collective’s interactive projects</p> 
                <br/>
                <p><i>Present Future – Outstanding Entries</i></p> 
                <p>Art & Technology Star Award competition by MANA</p> 
                <br/>
                <p><i>A Line Which Forms A Volume</i></p> 
                <p>a symposium organized by London College of Communication on design-led research</p> 
                <br/>
                <p><i>East Asian Ticket Club</i></p> 
                <p>a talk on Mirador Collective’s interactive projects</p> 
                <br/>
            </div>
        </div>

        <div className="divide-title">Co-founders</div>

        <div className="bio">
            <div className="left half float-left">
                <h2 className="about_title">Cristina Rosique</h2>
    
                <div className="about_long">
                    <p>Cristina is a visual creator and storyteller. Her specialty ranges from graphic design to architecture. She believes that design is more than the look of things, it is a powerful tool that brings upon social change, a tool of protest. Her personality makes her intolerant to social injustice. This is reflected in her critical practice which mainly focuses on gender inequalities and minorities issues. Cristina holds a Master of Graphic Media Design from University arts of London, Master of Space Syntax: Architecture & Cities from the Bartlett School of Architecture, UCL, as well as Bachelor’s and Master’s in Architecture. Her practice and research have been published in ALWFV2, Eve Gallery, and FGM manual.</p>
                </div>
            </div>
    
            <div className="left half float-right">
                <h2 className="about_title">Suki Law</h2>
    
                <div className="about_long">
                    <p>Suki is a visual designer and programmer based in London. Her work involves the application of critical design methodologies and digital medium in formulating meaningful stories. She believes that design has the ability to invite critical discussion and public engagement on solving social issues. Suki holds a MA Graphic Media Design from University of the Arts London as well as BComm in Law and Business.</p>
                </div>
            </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default About
