import React, { Component } from 'react';

class Shop extends Component {
  render() {
    return (
      <div className="content">
        <h2 className="center vertical-middle">Coming Soon</h2>
      </div>
    );
  }
}

export default Shop
