import React, { Component } from 'react';
import projectsJson from '../projects.json';
import { Link } from 'react-router-dom';
import Unity, { UnityContent } from "react-unity-webgl";
import { SoftImage } from '../components/SoftImage'

class Project extends Component {
  
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  allProjects = projectsJson;

  getProjectIndex(projectName){
    return this.allProjects.findIndex(element => { return element.dir_path === projectName });
  }

  getNextNProjects(fromHere, n){
    var nextProjects = [];
    for (var i = fromHere; i < fromHere + n; i++){
      nextProjects.push(this.allProjects[i % this.allProjects.length]);
    }

    return nextProjects;
  }

  textToHtml(text){
    return {__html: text};
  }

  render() {
    var projectIndex = this.getProjectIndex(this.props.match.params.projectName);
    var project = projectIndex > -1 ? this.allProjects[projectIndex] : {};
    var nextNProjects = this.getNextNProjects(projectIndex + 1, 3);

    var game;
    if (project.game != null){
      const unityContent = new UnityContent(
        process.env.PUBLIC_URL + "/" + project.dir_path + "_game/Build/WebGLGame.json",
        process.env.PUBLIC_URL + "/" + project.dir_path + "_game/Build/UnityLoader.js"
      );

      game = <Unity unityContent={unityContent} />;
    }

    var gameHowToPlay;
    if (project.game_how_to_play != null){
      gameHowToPlay = process.env.PUBLIC_URL + "/" + project.dir_path + "_game/how_to_play.png";
    }

    return (
      <div>
        <div className="content red">
          <div className="bio">

              <div className="left">
                  <div className="center">
                      <SoftImage 
                        alt={project.name} 
                        className="project-cover" 
                        src={require("../images/" + project.dir_path + "/thumbnails/big_" + project.pic_path)}/>
                  </div>
                  <div className="project-title">{project.name}</div>
              </div>

          </div>
        </div>

        <div className="content">
          <div className="bio">
              { project.description ? 
              <div className="left project-text">
                  <p className="p_text" dangerouslySetInnerHTML={this.textToHtml(project.description)}></p>
              </div> : null}

              { gameHowToPlay ? 
              <div className="center">
                <br/>
                <p>How to play:</p>
                <br/>
                <div><img width="200px" src={ gameHowToPlay }/></div>
                <br/>
                <p>Turn on sound for full experience.</p>
              </div> 
              
              : null}

              <br/>

              { project.game ? game : null }

              { project.additional_info ? <div><p className="p_text" dangerouslySetInnerHTML={this.textToHtml(project.additional_info)}></p></div> : null}

              <br/>

              {project.videos.map(video =>
                <video className="project-video center" controls key={video}>
                    <source src={require("../images/" + project.dir_path + "/" + video)} type="video/mp4"/>
                Your browser does not support HTML5 videos :(
                </video>
                )}
                  
              <br/>
          </div>

          <div className="more-projects">
              <div className="center">
                  <h3>More projects</h3>
              </div>
              <br/>

              {nextNProjects.map(project =>
                
                <div className="album_frame" key={project.dir_path}>
                    <img alt={project.name} src={require("../images/" + project.dir_path + "/thumbnails/" + project.pic_path)}/>
                    <Link to={ project.dir_path } className="overlay">
                        <div className="overlay-text">{ project.name }</div>
                    </Link>
                </div>
                
                )}
          
          </div>

          <br/>
          <br/>
          <br/>
          <br/>
            
        </div>
      </div>
      
    );
  }
}

export default Project
