import React, { Component } from 'react';
import Work from './Work';
import MainMenu from '../menus/MainMenu';
import {scrollToId} from '../functions/scroll'

class Home extends Component {

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    var hash = window.location.hash;
    if (window.location.hash !== ""){
      scrollToId(hash, 800);
    }
  }

  render() {
    return( 
      <div className="home_container">
        <MainMenu></MainMenu>
        <Work></Work>
      </div>
    );
  }
}

export default Home
