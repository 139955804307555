import React, { Component } from 'react';

class PopulationCounter extends Component {

    componentDidMount() {
        var START_POPULATION = 7683134322;
        var START_DAY = new Date('2019-01-01T00:00:00Z');

        var UPDATE_DAY = new Date('2019-11-11T21:49:00Z');
        var PEOPLE_BORN_UNTIL_UPDATE_DAY = 120861363;
        var PEOPLE_DIED_UNTIL_UPDATE_DAY = 50740509;

        var milliSecondsSinceStart = UPDATE_DAY - START_DAY;
        var avgNewPeoplePerMillisecond = PEOPLE_BORN_UNTIL_UPDATE_DAY / milliSecondsSinceStart;
        var avgDeadPeoplePerMillisecond = PEOPLE_DIED_UNTIL_UPDATE_DAY / milliSecondsSinceStart;
        
        var dateNow = Date.now();
        var millisecondsSinceUpdate = dateNow - UPDATE_DAY;

        // We assume that the rate of increase is the same throughout the year. This rate is fine.
        // However, this causes our initial population to be totally different at the beginning.
        // In order to correct the initial discrepancy, we can use the following offset. 
        var offset = 60151367;

        var populationNow = START_POPULATION + (millisecondsSinceUpdate * avgNewPeoplePerMillisecond) - (millisecondsSinceUpdate * avgDeadPeoplePerMillisecond) + offset;
        var currentPopulationHtmlElement = document.getElementById('current-population');

        // Measured in milliseconds. These define how fast we should update the counter with new and dead ppl. Purely for visuals.
        var newPeopleUpdateCycle = 300;
        var deadPeopleUpdateCycle = 700;

        this.newPeopleUpdater = window.setInterval(updateWithBorn, newPeopleUpdateCycle);
        function updateWithBorn() {
            populationNow += avgNewPeoplePerMillisecond * newPeopleUpdateCycle;
            updatePopulatioNow(populationNow);
        }

        this.deadPeopleUpdater = window.setInterval(updateWithDead, deadPeopleUpdateCycle);
        function updateWithDead() {
            populationNow -= avgDeadPeoplePerMillisecond * deadPeopleUpdateCycle;
            updatePopulatioNow(populationNow);
        }

        // Helper function for formatting and printing the population
        function updatePopulatioNow(newPopulation) {
            var prettyPopulation = Math.round(newPopulation).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            currentPopulationHtmlElement.innerHTML = prettyPopulation;
        }
    }
    componentWillUnmount() {
        clearInterval(this.newPeopleUpdater);
        clearInterval(this.deadPeopleUpdater);
    }

    render() {
        return (
            <p>There are <span id="current-population">7,684,420,075</span> realities on this planet.</p>
        )
    }
}

export default PopulationCounter
