import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';

class SideMenu extends Component {

  transformMenu() {
    var menuIcon = document.getElementById("menu-icon");
    var menuIconToggledClasses = "menu-toggle toggled";
    var menuItems = document.getElementById("responsive-list");
    var html = document.documentElement;
    if (menuItems.className === "mobile-menu") {
        menuItems.className = "";
        html.style.overflowY = 'scroll';
        menuIcon.className = "menu-toggle";
    } else {
        menuItems.className = "mobile-menu";
        html.style.overflowY = 'hidden';
        menuIcon.className = menuIconToggledClasses;
    }
  }

  componentDidMount() {
    var menuIcon = document.getElementById("menu-icon");
    menuIcon.addEventListener("click", this.transformMenu);
  }

  render() {
    return (

      <div className="menu">

        <div className="menu_content">
            <div className="page-logo">
                <Link className="logo-link" to="/"></Link>
            </div>

            <a className="menu-toggle" id="menu-icon">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </a>

            <div className='dashboard'>
                <li id="responsive-list" onClick={this.transformMenu}>
                    <ul>
                      <NavLink  
                        strict exact 
                        to="/#projects" 
                        activeClassName="active" 
                        isActive={(_match, location) => {
                          if (location.pathname.includes("projects")) { return true; }}}>WORK</NavLink>
                    </ul><ul>
                      <NavLink strict exact to="/about" activeClassName="active">ABOUT</NavLink>
                    </ul><ul>
                      <NavLink strict exact to="/shop" activeClassName="active">SHOP</NavLink>
                    </ul>
                </li>

            </div>
        </div>
          
      </div>

    )
  }
}

export default SideMenu
