import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from './timelines'
import SideMenu from './menus/SideMenu'
import Home from './views/Home'
import About from './views/About'
import Shop from './views/Shop'
import Project from './views/Project'

class App extends Component {

  render() {

    const HomeContainer = () => (

      <div className="container">
        <Route render={({ location }) => {

          const { pathname, key, hash } = location;
          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                onEnter={(node, appears) => play(pathname, node, appears)}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{enter: 250, exit: 150}}
              >
                <Switch location={location}>
                  <Route exact path="/" component={Home}/>
                </Switch>
              </Transition>
            </TransitionGroup>
          )
        }}/>

      </div>
    )

    const SiteContainer = () => (
      <div className="container">
        <SideMenu/>
        <Route render={({ location }) => {

          const { pathname, key, hash } = location;

          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                onEnter={(node, appears) => play(pathname, node, appears)}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{enter: 250, exit: 150}}
              >
                <Switch location={location}>
                  <Route path="/projects/:projectName" component={Project} />
                  <Route path="/about" component={About} />
                  <Route path="/shop" component={Shop} />
                </Switch>
              </Transition>
            </TransitionGroup>
          )
        }}/>

      </div>
    )
    
    return (
      <BrowserRouter>
        
        <div className="app">
          <Switch>
            <Route exact path="/" component={HomeContainer}/>
            <Route component={SiteContainer}/>
          </Switch>
        </div>
        
      </BrowserRouter>
    )
  }
}
  
  export default App;
