import React from 'react';
import projectsJson from '../projects.json';
import { Link } from 'react-router-dom';

class Work extends React.Component {
  
  render() {
    return (
        <div className="fill-width" id="projects">{projectsJson.map(project =>

          <div className="work_item" key={project.dir_path}>
            <Link to={"/projects/" + project.dir_path}>
              <div className="work_title" style={{backgroundImage: "url(" + require("../images/" + project.dir_path + "/thumbnails/text_" + project.dir_path + ".png" ) + ")"}}>
              </div>
              <div className="work_image">
                  <img alt={project.name} src={require("../images/" + project.dir_path + "/thumbnails/big_" + project.pic_path)}/>
              </div>
            </Link>
          </div>

          )}

          <div className="red">
            <br/>
          </div>

        </div>
    );
  }
}

export default Work
